import Component from 'classes/Component'
 

export default class Button extends Component {
    constructor(element) {
        super()

        this.el = element
        this.fill = this.el.querySelector('.btn-fill')
        this.text = this.el.querySelector('.btn-text')
    }

    init() {
        super.init()

        if (this.el.classList.contains('btn-static')) return
        splitLine(this.text)
    }

    pageLoaded() {
        super.pageLoaded()
    }

    update() {
        super.update()
    }

    resize() {
        super.resize()
    }

    destroy() {
        super.destroy()
    }
}